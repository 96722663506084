import React from 'react';
// import Switch from 'react-switch';

export default function CustomSwitch(props) {
  return (
    <div className="switch">
      <input className="toggle toggle-left"
        type="radio" checked={!props.checked}
        onChange={props.onChange}/>
      <label className="btn" onClick={props.onChange}>
        {props.textLeft}
      </label>
      <input className="toggle toggle-right"
        type="radio" checked={props.checked}
        onChange={props.onChange}/>
      <label className="btn" onClick={props.onChange}>
        {props.textRight}
      </label>
    </div>
  );

  // return (
  //   <label htmlFor="small-radius-switch">
  //     <Switch
  //       checked={props.checked}
  //       onChange={props.onChange}
  //       offColor="#A0A0A0"
  //       onColor="#A0A0A0"
  //       height={40}
  //       width={240}
  //       borderRadius={20}
  //       handleDiameter={40}
  //       activeBoxShadow="0px 0px 1px 2px #fffc35"
  //       uncheckedIcon={
  //         <div
  //           style={{
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             height: '100%',
  //             width: 100,
  //             color: 'white',
  //             textAlign: 'left',
  //           }}
  //         >
  //           {props.textRight}
  //         </div>
  //       }
  //       checkedIcon={
  //         <div
  //           style={{
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             height: '100%',
  //             width: 100,
  //             color: 'white',
  //           }}
  //         >
  //           {props.textLeft}
  //         </div>
  //       }
  //       uncheckedHandleIcon={
  //         <div
  //           style={{
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             height: '100%',
  //             width: 100,
  //             color: 'white',
  //             backgroundColor: '#D7314A',
  //             borderRadius: 20
  //           }}
  //         >
  //           {props.textLeft}
  //         </div>
  //       }
  //       checkedHandleIcon={
  //         <div
  //           style={{
  //             display: 'flex',
  //             justifyContent: 'center',
  //             alignItems: 'center',
  //             height: '100%',
  //             width: 100,
  //             color: 'white',
  //             backgroundColor: '#D7314A',
  //             borderRadius: 20
  //           }}
  //         >
  //           {props.textRight}
  //         </div>
  //       }
  //     />
  //   </label>
  // );
}
