import React, { useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import NavbarLinks from './NavbarLinks';
import NavbarLinksPartnership from './NavbarLinksPartnership';
import LanguageMenu from './LanguageMenu';
import { Navigation, Toggle, Hamburger, Close } from './navbar-styles';
import { LocalizedLink as Link } from '../../i18n/localized-link';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="right" ref={ref} {...props} />;
});

const DesktopLayout = ({ children }) => {
  return (
    <Box sx={{backgroundColor: '#FAFAFA'}}>
      <Container maxWidth="lg" disableGutters={true}>
        <Navigation style={{padding: '0 0px'}}>
          <Stack direction="row" spacing={3} sx={{flex: '1 0', alignItems: 'center', justifyContent: 'start'}}>
            {children}
          </Stack>
          <Stack direction="row" spacing={1} sx={{alignItems: 'center'}}>
            <LanguageMenu />
            <Link to='/app'>
              <StaticImage src="../../images/logo_more_than_taxi.svg" alt="more than taxi" height={30}/>
            </Link>
          </Stack>
        </Navigation>
      </Container>
    </Box>
  );
};

const MobileLayout = ({ children }) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const closeDialog = () => {
    setDialogOpen(false);
  };

  return (
    <Navigation style={{padding: 20}}>
      <Toggle navbarOpen={dialogOpen} onClick={() => setDialogOpen(!dialogOpen)} style={{flex: '1 0'}}>
        <Hamburger />
      </Toggle>
      <Link to='/app'>
        <StaticImage src="../../images/logo_more_than_taxi.svg" alt="more than taxi" height={30}/>
      </Link>
      <Dialog
        fullScreen
        open={dialogOpen}
        onClose={closeDialog}
        TransitionComponent={Transition}
      >
        <div style={{padding: 20, textAlign: 'center'}}>
          <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
            <div style={{display: 'inline-block'}}>
              <Close onClick={closeDialog} />
            </div>
            <StaticImage src="../../images/logo_more_than_taxi.svg" alt="more than taxi" height={30} />
          </div>
          <Box sx={{m: 2}} onClick={closeDialog} >
            <Stack direction="column" spacing={2}>
              {children}
            </Stack>
          </Box>
          <LanguageMenu/>
        </div>
      </Dialog>
    </Navigation>
  );
};

const Navbar = ({ layout }) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  const content = (layout === 'partnership') ? (
    <NavbarLinksPartnership />
  ) : (
    <NavbarLinks />
  );

  if (isDesktop) {
    return (
      <DesktopLayout >
        {content}
      </DesktopLayout >
    );
  }

  return (
    <MobileLayout >
      {content}
    </MobileLayout >
  );
};

export default Navbar;
