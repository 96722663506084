import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledLink } from './navbar-styles';

const NavbarLinks = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledLink to='/promotions'>{t('navbar.latest_promotions')}</StyledLink>
      <StyledLink to='/epayment'>{t('navbar.epayment_services')}</StyledLink>
      <StyledLink to='/street-hail'>{t('navbar.street_hail')}</StyledLink>
      <StyledLink to='/taxi-dollars'>Taxi Dollars</StyledLink>
      <StyledLink to='/contact'>{t('navbar.contact')}</StyledLink>
    </>
  );

};

export default NavbarLinks;
