import React from 'react';
import { useTranslation } from 'react-i18next';
import { StaticImage } from 'gatsby-plugin-image';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled as muiStyled } from '@mui/material/styles';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';

import { LocalizedLink as Link } from '../i18n/localized-link';
import { usePageContext } from '../i18n/page-context';

const StyledLink = muiStyled(Link)`
  color: white;
  display: block;
  padding: 10px 0;
  text-decoration: none;

  &:visited {
    color: white;
  }

  &:hover {
    color: ${({theme}) => theme.palette.primary.main};
  }
`;

const BreadcrumbLink = muiStyled(Link)`
  color: white;
  display: block;
  padding: 10px 0;
  text-decoration: none;

  &:visited {
    color: white;
  }

  &:hover {
    color: ${({theme}) => theme.palette.primary.main};
  }
`;

const NavBar = ({ crumbs }) => {
  const { t } = useTranslation('footer');
  const { lang } = usePageContext();

  if (!crumbs) {
    return null;
  }

  const getCrumbLabel = (label) => {
    return t(label.replaceAll('-', '_'));
  };

  const breadcrumbs = crumbs.map((crumb, i) => {
    if (lang === 'en' && i === 1) {
      return null;
    }

    if (!i) {
      return (
        <Link key={i} to="/app">
          <StaticImage src="../images/logo_text_white.png" alt="home" height={30} />
        </Link>
      );
    } else if (i === crumbs.length - 1) {
      return (
        <Typography key={i} color="white">
          {getCrumbLabel(crumb.crumbLabel)}
        </Typography>
      );
    }

    return (
      <BreadcrumbLink
        underline="hover"
        key={i}
        to={crumb.pathname}
      >
        {getCrumbLabel(crumb.crumbLabel)}
      </BreadcrumbLink>
    );
  });

  return (
    <Breadcrumbs
      separator={<ArrowRightIcon fontSize="small" style={{color: 'white'}}/>}
      aria-label="breadcrumb"
    >
      {breadcrumbs}
    </Breadcrumbs>
  );
};

const FooterLink = ({ text, link }) => {
  const { t } = useTranslation('footer');

  return (
    <Box>
      <StyledLink to={link}>
        {t(text)}
      </StyledLink>
    </Box>
  );
};

const FooterLayout = ({ children, layout, crumbs }) => {
  const { t } = useTranslation('footer');

  const links = (layout === 'partnership') ? (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      sx={{alignItems: 'start'}}
      spacing={1}
    >
      <a href="https://license.hktaxiapp.com" >
        <StaticImage src="../images/footer/footer_link_license.png" alt="" height={150}/>
      </a>
      <StyledLink to="/app" sx={{p: 0}}>
        <StaticImage src="../images/footer/footer_link_app.png" alt="" height={150}/>
      </StyledLink>
    </Stack>
  ) : (
    <Stack
      direction={{ xs: 'column', md: 'row' }}
      spacing={1}
    >
      <a href="https://license.hktaxiapp.com">
        <StaticImage src="../images/footer/footer_link_license.png" alt="" height={180}/>
      </a>
    </Stack>
  );

  return (
    <Box style={{zIndex: 2, position: 'relative'}}>
      <Box sx={{backgroundColor: '#525252', borderRadius: '20px 20px 0 0'}}>
        <Container maxWidth="lg" disableGutters={true}>
          <NavBar crumbs={crumbs} />
        </Container>
      </Box>
      <Box sx={{backgroundColor: '#6D6E71'}}>
        <Container maxWidth="lg" disableGutters={true}>
          <Stack sx={{justifyContent: 'space-between'}}
            direction={{ xs: 'column', md: 'row' }}
            spacing={1}
          >
            <Stack sx={{justifyContent: 'flex-start'}}
              direction={{ xs: 'column', md: 'row' }}
              spacing={2}
            >
              {children}
            </Stack>
            {links}
          </Stack>
          <Box sx={{fontSize: '0.8em', color: 'white', mt: 1}}>
            {t('copyright', {year: new Date().getFullYear()})}
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

const Footer = ({ layout, crumbs }) => {
  if (layout === 'partnership') {
    return (
      <FooterLayout crumbs={crumbs} layout={layout}>
        <Box>
          <FooterLink text="ads" link="/partnership/ads" />
          <FooterLink text="taxi_ads" link="/partnership/ads/taxi-ads" />
          <FooterLink text="driver_ads" link="/partnership/ads/driver-ads" />
        </Box>
        <Box>
          <FooterLink text="partner" link="/partnership/partner" />
          <FooterLink text="press" link="/partnership/partner/press" />
          <FooterLink text="campaigns" link="/partnership/partner/campaigns" />
        </Box>
        <Box>
          <FooterLink text="contact" link="/partnership/contact" />
        </Box>
      </FooterLayout>
    );
  }

  return (
    <FooterLayout crumbs={crumbs}>
      <Box>
        <FooterLink text="promotions" link="/promotions" />
        <FooterLink text="app" link="/app" />
        <FooterLink text="epayment" link="/epayment" />
      </Box>
      <Box>
        <FooterLink text="taxi_dollars" link="/taxi-dollars" />
        <FooterLink text="street_hail" link="/street-hail" />
        <FooterLink text="jobs" link="/career" />
      </Box>
      <Box>
        <FooterLink text="community" link="/community-guideline/" />
        <FooterLink text="contact" link="/contact" />
      </Box>
    </FooterLayout>
  );
};

export default Footer;
