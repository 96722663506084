import { createTheme } from '@mui/material/styles';

const COLOR_RED = '#D7314A';

const theme = createTheme({
  spacing: 20,
  palette: {
    primary: {
      main: COLOR_RED,
    },
  },
  components: {
    MuiContainer: {
      styleOverrides: {
        root: {
          padding: 20
        }
      }
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 20,
          padding: '4px 20px',
          boxShadow: 'none'
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: 'white'
        },
        '& fieldset': {
          borderColor: 'white'
        }
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          borderRadius: 10
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 20
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: 20
        },
      },
    },
    MuiCardActions: {
      styleOverrides: {
        root: {
          padding: 20
        },
      },
    }
  },
});

export default theme;
