import * as React from 'react';
import { Link } from 'gatsby';

import { localizedPath } from './helpers';
import { usePageContext } from './page-context';

export const LocalizedLink = ({ to, language, ...props }) => {
  const defaultLang = 'zh';
  const { lang } = usePageContext();

  const prefixDefault = false;
  const linkLocale = language || lang;

  return (
    <Link
      {...props}
      to={localizedPath({
        defaultLang,
        prefixDefault,
        locale: linkLocale,
        path: to,
      })}
    />
  );
};
