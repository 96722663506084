import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import theme from '../styles/theme';
import Navbar from './header/Navbar';
import Footer from './Footer';
import { SEO } from './SEO';

import '../styles/global.css';

const Wrapper = styled.main`
  min-height: 100%;
  display: flex;
  flex-direction: column;
`;

const Main = styled.div`
  flex: 1 0;
  margin-bottom: -20px;
  z-index: 1;
  position: relative;
`;

const HomeFooter = styled.div`
  font-size: 0.8em;
  color: #666;
  padding: 20px;
  bottom: 0;
  text-align: center;
`;

const Layout = ({ children, pageContext }) => {
  const { t } = useTranslation('footer');

  if (pageContext.layout === 'index') {
    return (
      <div style={{height: '100%'}}>
        <SEO />
        <ThemeProvider theme={theme}>
          {children}
        </ThemeProvider>
      </div>
    );
  }

  // Custom header
  let header;

  if (pageContext.layout === 'partnership') {
    header = (<Navbar layout="partnership"/>);
  } else if (pageContext.layout !== 'index') {
    header = (<Navbar />);
  }

  // Custom footer
  let footer;
  const crumbs = pageContext.breadcrumb && pageContext.breadcrumb.crumbs;

  if (pageContext.layout === 'partnership') {
    footer = (
      <Footer layout="partnership" crumbs={crumbs}/>
    );
  } else if (pageContext.layout === 'index') {
    footer = (
      <HomeFooter >
        {t('copyright', {year: new Date().getFullYear()})}
      </HomeFooter >
    );
  } else {
    footer = (
      <Footer crumbs={crumbs} />
    );
  }

  return (
    <div style={{height: '100%'}}>
      <SEO />
      <ThemeProvider theme={theme}>
        <Wrapper>
          {header}
          <Main>{children}</Main>
          {footer}
        </Wrapper>
      </ThemeProvider>
    </div>
  );
};

export default Layout;

// export const Head = () => (
//   <SEO />
// );
