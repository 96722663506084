exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-app-js": () => import("./../../../src/pages/app.js" /* webpackChunkName: "component---src-pages-app-js" */),
  "component---src-pages-career-apply-js": () => import("./../../../src/pages/career/apply.js" /* webpackChunkName: "component---src-pages-career-apply-js" */),
  "component---src-pages-career-index-js": () => import("./../../../src/pages/career/index.js" /* webpackChunkName: "component---src-pages-career-index-js" */),
  "component---src-pages-career-jobs-js": () => import("./../../../src/pages/career/jobs.js" /* webpackChunkName: "component---src-pages-career-jobs-js" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-campaigns-mastercard-0-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/campaigns/mastercard0.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-campaigns-mastercard-0-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-campaigns-mastercard-1-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/campaigns/mastercard1.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-campaigns-mastercard-1-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-jobs-accounting-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/jobs/accounting.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-jobs-accounting-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-jobs-social-media-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/jobs/social-media.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-jobs-social-media-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-jobs-software-engineer-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/jobs/software-engineer.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-jobs-software-engineer-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-jobs-ux-designer-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/jobs/ux-designer.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-jobs-ux-designer-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-am-730-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/am730.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-am-730-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-ezone-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/ezone.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-ezone-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-hket-title-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/hket-title.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-hket-title-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-pcm-index-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/pcm/index.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-pcm-index-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-unwire-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/unwire.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-press-unwire-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-citi-index-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/citi/index.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-citi-index-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-index-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mastercard/index.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-index-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-tnc-en-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mastercard/tnc-en.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-tnc-en-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-tnc-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mastercard/tnc.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-tnc-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-0-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mox0.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-0-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-1-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mox1.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-1-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-2-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mox2.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-2-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mox.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-ucr-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/ucr.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-data-promotions-ucr-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-community-guideline-en-mdx": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/community-guideline.en.mdx" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-community-guideline-en-mdx" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-community-guideline-zh-mdx": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/community-guideline.zh.mdx" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-community-guideline-zh-mdx" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-promotions-mastercard-tnc-en-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/promotions/mastercard/tnc.en.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-promotions-mastercard-tnc-en-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-promotions-mastercard-tnc-zh-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/promotions/mastercard/tnc.zh.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-promotions-mastercard-tnc-zh-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-taxi-dollars-tnc-en-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/taxi-dollars-tnc.en.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-taxi-dollars-tnc-en-md" */),
  "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-taxi-dollars-tnc-zh-md": () => import("./../../../src/pages/career/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/taxi-dollars-tnc.zh.md" /* webpackChunkName: "component---src-pages-career-mdx-fields-slug-js-content-file-path-src-pages-taxi-dollars-tnc-zh-md" */),
  "component---src-pages-career-policy-js": () => import("./../../../src/pages/career/policy.js" /* webpackChunkName: "component---src-pages-career-policy-js" */),
  "component---src-pages-community-guideline-en-mdx": () => import("./../../../src/pages/community-guideline.en.mdx" /* webpackChunkName: "component---src-pages-community-guideline-en-mdx" */),
  "component---src-pages-community-guideline-zh-mdx": () => import("./../../../src/pages/community-guideline.zh.mdx" /* webpackChunkName: "component---src-pages-community-guideline-zh-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-epayment-js": () => import("./../../../src/pages/epayment.js" /* webpackChunkName: "component---src-pages-epayment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-partnership-ads-ads-reference-js": () => import("./../../../src/pages/partnership/ads/ads-reference.js" /* webpackChunkName: "component---src-pages-partnership-ads-ads-reference-js" */),
  "component---src-pages-partnership-ads-driver-ads-js": () => import("./../../../src/pages/partnership/ads/driver-ads.js" /* webpackChunkName: "component---src-pages-partnership-ads-driver-ads-js" */),
  "component---src-pages-partnership-ads-index-js": () => import("./../../../src/pages/partnership/ads/index.js" /* webpackChunkName: "component---src-pages-partnership-ads-index-js" */),
  "component---src-pages-partnership-ads-taxi-ads-js": () => import("./../../../src/pages/partnership/ads/taxi-ads.js" /* webpackChunkName: "component---src-pages-partnership-ads-taxi-ads-js" */),
  "component---src-pages-partnership-contact-js": () => import("./../../../src/pages/partnership/contact.js" /* webpackChunkName: "component---src-pages-partnership-contact-js" */),
  "component---src-pages-partnership-index-js": () => import("./../../../src/pages/partnership/index.js" /* webpackChunkName: "component---src-pages-partnership-index-js" */),
  "component---src-pages-partnership-partner-campaigns-citi-js": () => import("./../../../src/pages/partnership/partner/campaigns/citi.js" /* webpackChunkName: "component---src-pages-partnership-partner-campaigns-citi-js" */),
  "component---src-pages-partnership-partner-campaigns-index-js": () => import("./../../../src/pages/partnership/partner/campaigns/index.js" /* webpackChunkName: "component---src-pages-partnership-partner-campaigns-index-js" */),
  "component---src-pages-partnership-partner-index-js": () => import("./../../../src/pages/partnership/partner/index.js" /* webpackChunkName: "component---src-pages-partnership-partner-index-js" */),
  "component---src-pages-partnership-partner-press-index-js": () => import("./../../../src/pages/partnership/partner/press/index.js" /* webpackChunkName: "component---src-pages-partnership-partner-press-index-js" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-campaigns-mastercard-0-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/campaigns/mastercard0.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-campaigns-mastercard-0-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-campaigns-mastercard-1-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/campaigns/mastercard1.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-campaigns-mastercard-1-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-jobs-accounting-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/jobs/accounting.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-jobs-accounting-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-jobs-social-media-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/jobs/social-media.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-jobs-social-media-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-jobs-software-engineer-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/jobs/software-engineer.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-jobs-software-engineer-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-jobs-ux-designer-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/jobs/ux-designer.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-jobs-ux-designer-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-am-730-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/am730.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-am-730-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-ezone-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/ezone.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-ezone-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-hket-title-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/hket-title.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-hket-title-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-pcm-index-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/pcm/index.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-pcm-index-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-unwire-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/press/unwire.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-press-unwire-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-citi-index-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/citi/index.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-citi-index-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-index-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mastercard/index.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-index-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-tnc-en-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mastercard/tnc-en.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-tnc-en-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-tnc-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mastercard/tnc.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mastercard-tnc-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-0-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mox0.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-0-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-1-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mox1.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-1-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-2-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mox2.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-2-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/mox.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-mox-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-ucr-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/data/promotions/ucr.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-data-promotions-ucr-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-community-guideline-en-mdx": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/community-guideline.en.mdx" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-community-guideline-en-mdx" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-community-guideline-zh-mdx": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/community-guideline.zh.mdx" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-community-guideline-zh-mdx" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-promotions-mastercard-tnc-en-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/promotions/mastercard/tnc.en.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-promotions-mastercard-tnc-en-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-promotions-mastercard-tnc-zh-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/promotions/mastercard/tnc.zh.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-promotions-mastercard-tnc-zh-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-taxi-dollars-tnc-en-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/taxi-dollars-tnc.en.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-taxi-dollars-tnc-en-md" */),
  "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-taxi-dollars-tnc-zh-md": () => import("./../../../src/pages/partnership/partner/press/{mdx.fields__slug}.js?__contentFilePath=/opt/build/repo/blog/src/pages/taxi-dollars-tnc.zh.md" /* webpackChunkName: "component---src-pages-partnership-partner-press-mdx-fields-slug-js-content-file-path-src-pages-taxi-dollars-tnc-zh-md" */),
  "component---src-pages-promotions-citi-js": () => import("./../../../src/pages/promotions/citi.js" /* webpackChunkName: "component---src-pages-promotions-citi-js" */),
  "component---src-pages-promotions-index-js": () => import("./../../../src/pages/promotions/index.js" /* webpackChunkName: "component---src-pages-promotions-index-js" */),
  "component---src-pages-promotions-mastercard-index-js": () => import("./../../../src/pages/promotions/mastercard/index.js" /* webpackChunkName: "component---src-pages-promotions-mastercard-index-js" */),
  "component---src-pages-promotions-mastercard-tnc-en-md": () => import("./../../../src/pages/promotions/mastercard/tnc.en.md" /* webpackChunkName: "component---src-pages-promotions-mastercard-tnc-en-md" */),
  "component---src-pages-promotions-mastercard-tnc-zh-md": () => import("./../../../src/pages/promotions/mastercard/tnc.zh.md" /* webpackChunkName: "component---src-pages-promotions-mastercard-tnc-zh-md" */),
  "component---src-pages-street-hail-js": () => import("./../../../src/pages/street-hail.js" /* webpackChunkName: "component---src-pages-street-hail-js" */),
  "component---src-pages-taxi-dollars-gift-js": () => import("./../../../src/pages/taxi-dollars-gift.js" /* webpackChunkName: "component---src-pages-taxi-dollars-gift-js" */),
  "component---src-pages-taxi-dollars-js": () => import("./../../../src/pages/taxi-dollars.js" /* webpackChunkName: "component---src-pages-taxi-dollars-js" */),
  "component---src-pages-taxi-dollars-tnc-en-md": () => import("./../../../src/pages/taxi-dollars-tnc.en.md" /* webpackChunkName: "component---src-pages-taxi-dollars-tnc-en-md" */),
  "component---src-pages-taxi-dollars-tnc-zh-md": () => import("./../../../src/pages/taxi-dollars-tnc.zh.md" /* webpackChunkName: "component---src-pages-taxi-dollars-tnc-zh-md" */)
}

