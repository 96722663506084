import styled from 'styled-components';

import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { styled as muiStyled } from '@mui/material/styles';

import { LocalizedLink as Link } from '../../i18n/localized-link';

export const Navigation = styled.div`
  position: sticky;
  display: flex;
  align-items: center;
`;

export const Toggle = styled.div`
  display: none;
  cursor: pointer;
  padding: 0 0px;

  @media (max-width: 1000px) {
    display: flex;
    flex-direction: column;
  }
`;

export const Navbox = styled.div`
  display: flex;
  flex: 1 0;
  align-items: center;
`;

export const Hamburger = styled(MenuIcon)`
  color: #B12B2B;
`;

export const Close = styled(CloseIcon)`
  color: #C42D2E;
  cursor: pointer;
`;

export const StyledLink = muiStyled(Link)`
  color: #333;
  display: block;
  margin-right: 0px;
  text-decoration: none;

  &:visited {
    color: #333;
  }

  &:hover {
    color: ${({theme}) => theme.palette.primary.main};
  }
`;

export const NavItem = styled(Link)`
  text-decoration: none;
  padding: 20px;
  color: black;
  text-align: center;
  display: block;

  :hover {
    color: #EC1B2F;
  }

`;
