import i18next from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18next.use(LanguageDetector).init({
  fallbackLng: 'en',
  resources: {
    zh: {
      ads: require('../locales/zh/ads.json'),
      footer: require('../locales/zh/footer.json'),
      form: require('../locales/zh/form.json'),
      job: require('../locales/zh/job.json'),
      partnership: require('../locales/zh/partnership.json'),
      translation: require('../locales/zh/translation.json'),
      validation: require('../locales/zh/validation.json'),
      epayment: require('../locales/zh/epayment.json'),
      taxi_dollars: require('../locales/zh/taxi_dollars.json'),
      street_hail: require('../locales/zh/street_hail.json')
    },
    en: {
      ads: require('../locales/en/ads.json'),
      footer: require('../locales/en/footer.json'),
      form: require('../locales/en/form.json'),
      job: require('../locales/en/job.json'),
      partnership: require('../locales/en/partnership.json'),
      translation: require('../locales/en/translation.json'),
      validation: require('../locales/en/validation.json'),
      epayment: require('../locales/en/epayment.json'),
      taxi_dollars: require('../locales/en/taxi_dollars.json'),
      street_hail: require('../locales/en/street_hail.json')
    }
  },
  ns: ['translation', 'footer', 'job', 'form'],
  defaultNS: 'translation',
  returnObjects: true,
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
  // react: {
  //   wait: true,
  // },
});

i18next.languages = ['zh', 'en'];

export default i18next;
