import React from 'react';
import { useTranslation } from 'react-i18next';

import { StyledLink } from './navbar-styles';

const NavbarLinks = () => {
  const { t } = useTranslation();

  return (
    <>
      <StyledLink to='/partnership/ads'>{t('navbar.ads')}</StyledLink>
      <StyledLink to='/partnership/partner'>{t('navbar.partner')}</StyledLink>
    </>
  );
};

export default NavbarLinks;
