import React from 'react';
import { styled as muiStyled } from '@mui/material/styles';

import Switch from '../../components/Switch';
import { LocalizedLink as Link  } from '../../i18n/localized-link';
import { usePageContext  } from '../../i18n/page-context';
import { localizedPath } from '../../i18n/helpers';

const StyledLink = muiStyled(Link)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  border: 'none',
  color: 'white',
  padding: '8px 20px',
  textAlign: 'center',
  textDecoration: 'none',
  fontSize: '14px',
  borderRadius: '20px',
  display: 'inline-block'
}));

const LanguageMenu = () => {
  const { originalPath, lang } = usePageContext();

  // if (lang !== 'en') {
  //   return (
  //     <StyledLink to={originalPath} language="en">
  //       ENG
  //     </StyledLink>
  //   );
  // } else {
  //   return (
  //     <StyledLink to={originalPath} language="zh">
  //       中文
  //     </StyledLink>
  //   );
  // }

  const onToggle = () => {
    location.href = localizedPath({
      defaultLang: 'zh',
      prefixDefault: false,
      locale: (lang === 'en' ? 'zh' : 'en'),
      path: originalPath,
    });
  };

  return (
    <Switch checked={lang === 'en'}
      textLeft="中文" textRight="ENG"
      onChange={() => onToggle()} />
  );

};

export default LanguageMenu;
